<template>
  <b-container>
    <b-row v-if="!loadingIntroTopic" class="my-4">
      <b-col>
        <b-card>
          <b-container>
            <b-row>
              <b-col>
                <p><b-link :to="{ name: 'Topics' }">back to topics</b-link></p>
                <h2>
                  {{topic.name}}
                  <span v-if="$config.BUILD === 'full'">
                    <subscription
                      :id="topic.id"
                      content="topics"
                    />
                  </span>
                  <b-button class="mr-2" v-if="permissionEditTopic" variant="outline-primary" :to="{ name: 'TopicEdit', params: { id: topic.id }}">Edit</b-button>
                </h2>
                <p><markdown :content="topic.comments" /></p>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col class="mb-2">
                <div><strong>Development stage</strong></div>
                <div>{{topic.trackingStatus}}</div>
              </b-col>
              <b-col class="mb-2">
                <div><strong>Sectors</strong></div>
                <div><div v-for="item in topic.sectors" :key="item">{{item}}</div></div>
              </b-col>
              <b-col class="mb-2" v-if="$config.BUILD === 'full'">
                <edge-select-list
                      v-if="!loadingTopic"
                      directionProp="from vertex"
                      edgeLabelProp="inCategory"
                      :editableProp="false"
                      :listSourceProp="{ partition: 'events', label: 'category' }"
                      nameProp="Category"
                      :vertexProp="topic"
                      />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" lg="6" class="mb-4">
                 <div id="regulatorschart" style="width: 100%; height: 400px;"></div>
              </b-col>
              <b-col md="12" lg="6" class="mb-4">
                  <div id="countrieschart" style="width: 100%; height: 400px;"></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <strong>Monthly regulatory news volume</strong>
                <div id="topicStats" style="width: 100%; height: 250px;"></div>
              </b-col>
            </b-row>
            <b-row v-if="topKeywords">
              <b-col>
                <div><strong>Top keywords over last six months</strong></div>
                <span v-for="(keyword, index) in topKeywords" :key="'keyword' + keyword.name">
                  <b-link :to="{name: 'Keyword', params: { keyword: encodeURIComponent(keyword.name) }}">{{ keyword.name }}</b-link>
                  <a v-if="index + 1 < topKeywords.length"> | </a>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="my-5" v-if="$config.BUILD === 'full' && aipost">
      <b-col>
        <b-card>
          <p class="mb-4">Summary of recent developments generated automatically by Gen AI - may contain mistakes and hallucinations</p>
          <h4 class="mb-3">{{ aipost.name }}</h4>
          <div class="mb-3">{{ aipost.created_at.slice(0,10) }}</div>
          <div>
            <markdown :content="aipost.body" />
          </div>
          <div v-if="permissionEditTopic">
            <b-button class="mr-3" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: aipost.id, model: 'aipost' }}">Edit</b-button>
            <delete-standard :id="aipost.id" model="aipost" @deleted="loadTopic"></delete-standard>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body v-if="!loadingTopic">
      <b-tabs card>
        <b-tab title="News" active>
          <b-card-text>
            <h4>News</h4>
            <p>Up to 50 most recent news, for more use <b-link :to="{ name: 'NewsAdvancedFilter', query: { topic: this.$route.params.id } }">advanced filter</b-link></p>
            <div class="mb-4">
              <b-button class="mr-2" variant="outline-primary" v-on:click="getXlsNews">download</b-button>
              <b-button class="mr-2" variant="outline-primary" :to="{ name: 'NewsAdvancedFilter', query: { topic: this.$route.params.id } }">advanced filter</b-button>
            </div>
            <div v-for="item in news" :key="item.id">
              <div class="mb-2 pb-2 border-bottom">
              <publication
                :propItem="item"
                :key="item.id"
                :tagsProp=tags
                :username="user.username"
                :permissionAddArticle="permissionAddArticle"
                :permissionAddRisk="permissionAddRisk"
                :permissionAddTag="permissionAddTag"
                :permissionArticlesWip="permissionArticlesWip"
                :permissionDeletePublication="permissionDeletePublication"
                :permissionInsights="permissionInsights"
                :permissionReadTagsPanel="permissionReadTagsPanel"
               />
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Events" v-on:click="showEvents">
          <b-card-text>
            <h4>Events</h4>
            <div class="mb-4"><b-button variant="outline-primary" v-on:click="getXlsEvents">download</b-button></div>
            <div v-for="event in events" :key="event.id">
              <div class="mb-2 pb-2 border-bottom">
                <event
                  :event="event"
                  :permissions="eventPermissions"
                  :username="user.username"
                  :showRelatedNews="true"
                />
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="KPMG Insights" v-on:click="showKpmgInsights" v-if="permissionInsights">
          <b-card-text>
            <h4>KPMG Insights</h4>
            <b-button class="mr-2" variant="outline-primary" :to="{ name: 'Articles', query: { topic: this.$route.params.id } }">advanced filter</b-button>
            <span>
              <div class="m-0" v-for="item in articles" :key="'publication-' + item.id">
                <div class="mb-2 pb-2 border-bottom">
                  <Article
                    :hideLink="false"
                    :propItem="item"
                    :key="item.id"
                  />
                </div>
              </div>
            </span>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import zipcelx from 'zipcelx'

import ac from '../libs/accesscontrol'
import theme from '@/libs/colorTheme'

import DeleteStandard from '@/components/DeleteStandard.vue'
import EdgeSelectList from '@/components/cosmos/EdgeSelectList.vue'
import Event from '@/components/Event.vue'
import Markdown from '@/components/Markdown.vue'
import Publication from '@/components/PublicationNew'
import Article from '@/components/Article'
import Subscription from '@/components/Subscription.vue'

import * as echarts from 'echarts'
echarts.registerTheme('kpmg', theme)

export default {
  name: 'Topic',
  components: {
    DeleteStandard,
    EdgeSelectList,
    Event,
    Markdown,
    Publication,
    Article,
    Subscription
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'topic', action: 'open topic', model: 'topic', model_id: this.$route.params.id })
    this.permissionEditTopic = ac.can(this.user.acgroups).createAny('topicUpdate').granted
    this.permissionReadTopicUpdate = ac.can(this.user.acgroups).readAny('topicUpdate').granted
    this.eventPermissions.add = ac.can(this.user.acgroups).createAny('event').granted
    this.eventPermissions.delete = ac.can(this.user.acgroups).deleteAny('event').granted
    this.eventPermissions.edit = ac.can(this.user.acgroups).updateAny('event').granted
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionEditTopic = false
      this.permissionReadTopicUpdate = false
      this.eventPermissions.add = false
      this.eventPermissions.delete = false
      this.eventPermissions.edit = false
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadTagsPanel = false
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.loadTopic()
  },
  data () {
    return {
      aipost: null,
      articles: [],
      events: [],
      eventPermissions: {
        add: false,
        delete: false,
        edit: false
      },
      loadingIntroTopic: true,
      loadingTopic: true,
      news: [],
      permissionReadTopicUpdate: false,
      permissionEditTopic: false,
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadTagsPanel: false,
      show: {
        abstract: false,
        news: true,
        events: false
      },
      tags: [],
      topic: '',
      topKeywords: null
    }
  },
  methods: {
    clearShowFlags: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    getXlsEvents: function () {
      this.$stat.log({ page: 'topic', action: 'download events', model: 'topic', model_id: this.$route.params.id })
      let data = []
      const headings = [
        { value: 'EventId', type: 'string' },
        { value: 'Date', type: 'string' },
        { value: 'Title', type: 'string' },
        { value: 'Details', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.events.length; i < len; i++) {
        let row = [
          { value: this.events[i].id, type: 'string' },
          { value: this.events[i].date, type: 'string' },
          { value: this.events[i].name, type: 'string' },
          { value: this.events[i].comments, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'reg-topic-events', sheet: { data: data } }
      zipcelx(config)
    },
    getXlsNews: function () {
      this.$stat.log({ page: 'topic', action: 'download news', model: 'topic', model_id: this.$route.params.id })
      let data = []
      const headings = [
        { value: 'NewsId', type: 'string' },
        { value: 'Title', type: 'string' },
        { value: 'Date', type: 'string' },
        { value: 'Source', type: 'string' },
        { value: 'Details', type: 'string' },
        { value: 'Link', type: 'string' }

      ]
      data.push(headings)
      for (let i = 0, len = this.news.length; i < len; i++) {
        let row = [
          { value: this.news[i].id, type: 'string' },
          { value: this.news[i].name, type: 'string' },
          { value: this.news[i].date, type: 'string' },
          { value: this.news[i].source, type: 'string' },
          { value: this.news[i].body, type: 'string' },
          { value: this.news[i].link, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'reg-topic-news', sheet: { data: data } }
      zipcelx(config)
    },
    loadTopic: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingTopic = true
      try {
        let apiName = 'cosmos'
        let path = `/topic/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.topic = response
        document.title = this.topic.name
        this.topic.partition = 'events'
        this.loadingIntroTopic = false
      } catch (e) {
        this.$logger.warn('/topic/:id error ', e)
      }
      try {
        let apiName = 'cosmos'
        let path = `/news/topic/${this.topic.id}/standardformat/limit/50`
        let response = await this.$Amplify.API.get(apiName, path)
        this.news = response
        this.loadingTopic = false
        let countries = []
        let regulators = []
        _.each(_.countBy(this.news, x => x.publisher), (value, key) => {
          regulators.push({ name: key, value: value })
        })
        regulators = _.sortBy(regulators, ['value'])
        _.each(_.countBy(this.news, x => x.countryName), (value, key) => {
          countries.push({ name: key, value: value })
        })
        countries = _.sortBy(countries, ['value'])
        const optionCountries = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: [
            {
              name: 'Countries',
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['50%', '50%'],
              data: countries,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        const optionRegulators = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: [
            {
              name: 'Regulators',
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['50%', '50%'],
              data: regulators,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        echarts.init(document.getElementById('countrieschart'), 'kpmg').setOption(optionCountries)
        echarts.init(document.getElementById('regulatorschart'), 'kpmg').setOption(optionRegulators)
        if (this.$config.BUILD === 'full') {
          this.aipost = null
          const aiposts = await this.$Amplify.API.get('cosmos', `/aiposts/topic/${this.topic.id}/limit/1/offset/0`)
          if (aiposts.length > 0) {
            this.aipost = aiposts[0]
          }
        }
        const endDate = moment().format('YYYY-MM-DD')
        let startDate = moment().subtract(3, 'years').format('YYYY-MM-DD')
        const topicStats = await this.$Amplify.API.get('cosmos', `/stats/topic/${this.topic.id}/${startDate}/${endDate}/month`)
        const optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: topicStats
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('topicStats'), 'kpmg').setOption(optionTrend)
        startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
        this.topKeywords = await this.$Amplify.API.get('cosmos', `/stats/top/keywords-in-topic/${this.topic.id}/${startDate}/${endDate}/10`)
      } catch (e) {
        this.$logger.warn('/news/topic/:id error ', e)
      }
    },
    removeNewsItem: async function (event) {
      const key = event.currentTarget.getAttribute('uid')
      let newsToRemove = _.find(this.news, ['id', key])
      let newsTemp = this.news
      try {
        let apiName = 'cosmos'
        let path = `/news/topic`
        let params = {
          body: {
            topicId: this.topic.id,
            publicationId: key,
            userId: this.user.username
          }
        }
        await this.$Amplify.API.del(apiName, path, params)
        _.remove(newsTemp, _.matchesProperty('id', newsToRemove.id))
        this.news = []
        this.news = newsTemp
      } catch (e) {
        this.$logger.warn('error addRegTopic: ', e)
      }
    },
    showAbstract: function () {
      this.$stat.log({ page: 'topic', action: 'show abstract', model: 'topic', model_id: this.$route.params.id })
      this.$logger.debug('showAbstract started')
      this.clearShowFlags()
      this.show.abstract = true
    },
    showEvents: async function () {
      this.$stat.log({ page: 'topic', action: 'show events', model: 'topic', model_id: this.$route.params.id })
      this.$logger.debug('showEvents started')
      this.clearShowFlags()
      try {
        let apiName = 'cosmos'
        let path = `/topic/${this.$route.params.id}/events`
        let response = await this.$Amplify.API.get(apiName, path)
        this.events = _.orderBy(response, ['date'], ['desc'])
        this.show.events = true
      } catch (e) {
        this.$logger.warn('loading events error' + e)
      }
    },
    showKpmgInsights: async function () {
      this.$stat.log({ page: 'topic', action: 'show insights', model: 'topic', model_id: this.$route.params.id })
      this.$logger.debug('showKpmgInsights started')
      this.clearShowFlags()
      try {
        let apiName = 'cosmos'
        let path = `/articles/topic/${this.topic.id}/limit/50`
        let response = await this.$Amplify.API.get(apiName, path)
        this.articles = _.orderBy(response, ['date'], ['desc'])
      } catch (e) {
        this.$logger.warn('loading events error' + e)
      }
    },
    showNews: function () {
      this.$stat.log({ page: 'topic', action: 'show news', model: 'topic', model_id: this.$route.params.id })
      this.$logger.debug('showNews started')
      this.clearShowFlags()
      this.show.news = true
    }
  },
  watch: {
    '$route.params.id': 'loadTopic'
  }
}
</script>

<style scoped>

* {
  box-sizing: border-box;
}

/* Set a background color */

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: white;
  border: 4px solid #FF9F55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.timeline-left {
  left: 0;
}

/* Place the container to the right */
.timeline-right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.timeline-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline-right::after {
  left: -13px;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
/* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

/* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

/* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

/* Make sure all circles are at the same spot */
  .timeline-left::after, .timeline-right::after {
    left: 15px;
  }

/* Make all right containers behave like the left ones */
  .timeline-right {
    left: 0%;
  }
}
</style>
